import React from 'react'
import Layout from '../components/layout'
import MainContent from '../components/mainContent'
import { Row, Col } from 'reactstrap'

const NotFoundPage = () => (
  <Layout>
    <MainContent>
      <Row>
        <Col className='error-wrap text-center'>
          <h1 className='error-code'>404</h1>
          <h2 className='error-message h3'>Page not found</h2>
          <p className='message-manual'>
            Unfortunately the page you were looking for could not be found.
          </p>
          <a
            href='http://gro.gbjsolution.com'
            className='btn btn-primary back-btn'
          >
            Visit Home page{' '}
            <svg
              width='24'
              height='24'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z' />
            </svg>
          </a>
        </Col>
      </Row>
    </MainContent>
  </Layout>
)

export default NotFoundPage
